.about__section-item{
    column-gap:3rem;
}
.about__img:first-child  {
margin-bottom:-110px;
padding-top: -40px ;
margin-top:200;

}
.about__section-item p i{
    color:#f9a826;
    padding-bottom: 10px;
    
}
.about__img >img {
    margin-bottom:80px
}