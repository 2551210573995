.marcedesImg2 {
    border-radius: 15px;
    width: 450px;
    padding: 4px;
    display: block;
    margin-top: 55px;


}

.marcedesImg3 {
    border-radius: 15px;
    width: 450px;
    padding: 4px;
    display: block;
    margin-top: 24px;


}

.Cardheadertext {
    margin-left: 80px;
    font-size: 1.5rem;
}