.social__link-icon {
    text-decoration: none;
    color: #000d6b;
    font-weight: 500;
    font-size: 1.2rem;
  }
  
  .contact__btn {
    padding: 7px 15px;
    background: #000d6b;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
  }
  
  @media only screen and (max-width: 768px) {
    .contact__btn {
      margin-bottom: 40px;
      font-size: 0.8rem;
      margin-left:40px;    }
    .booking__form {
      width: 100%;
    }
  
    .booking__form input,
    .booking__form select {
      font-size: 0.8rem;
      margin-left:10px;
      margin-right: 20px;

    }

    .textarea{
      margin-left:25px;
      width:100%;
      border: 1px solid #7c8a972a;
      color:#7c8a97;
      border-radius: 5px;
  
    }

  }

  